<script lang="ts" setup>
import type { MenuItemClicked } from 'element-plus';
import { useSidemenuStore } from '@/stores/sidemenu';
import useLoginStore from '@/stores/login';
import manualList from '@/assets/manual/list';

interface MenuItem {
  name: string
  authKey: string
  icon: string
  route: any
  class: any
  children: MenuChild[]
}
interface MenuChild {
  name: string
  route: any
  class: any
  tag?: string
}

const route = useRoute();
const router = useRouter();
const loginStore = useLoginStore();

const corpDynamicField = computed(() => loginStore.corpDynamicField);
const sidemenuStore = useSidemenuStore();
const globalSidemenu = computed(() => sidemenuStore.globalSidemenu);

const menuList = computed<MenuItem[]>(() => ([
  {
    name: '總覽',
    authKey: 'm_a',
    icon: 'vue-feather-home',
    route: { name: 'home' },
    class: { 'is-current': route.meta.firstRoute === '首頁' },
    children: [],
  },
  {
    name: '報表戰情室',
    authKey: 'm_b',
    icon: 'icon-audience',
    route: {},
    class: { 'is-current': route.meta.firstRoute === '報表戰情室' },
    children: [
      {
        name: '瀏覽行為分析',
        route: { name: 'reportBrowse' },
        class: { 'is-active': route.name === 'reportBrowse' },
      },
      {
        name: '銷售指標',
        route: { name: 'reportSales' },
        class: { 'is-active': route.name === 'reportSales' },
      },
      {
        name: '受眾比較分析',
        route: { name: 'reportTarget' },
        class: { 'is-active': route.name === 'reportTarget' },
      },
      {
        name: '商品指標',
        route: { name: 'reportProduct' },
        class: { 'is-active': route.name === 'reportProduct' },
      },
      {
        name: '洞察報告',
        route: { name: 'reportInsight' },
        class: { 'is-active': route.name === 'reportInsight' },
        tag: 'BETA',
      },
    ],
  },
  {
    name: '外部數據洞察',
    authKey: 'm_c',
    icon: 'vue-feather-globe',
    route: 'externalUrl',
    class: { 'is-current': route.meta.firstRoute === '外部數據洞察' },
    children: [],
  },
  {
    name: '會員管理',
    authKey: 'm_d',
    icon: 'vue-feather-users',
    route: { name: 'memberlist' },
    class: { 'is-current': route.meta.firstRoute === '會員管理' },
    children: [],
  },
  {
    name: '標籤管理',
    authKey: 'm_e',
    icon: 'vue-feather-tag',
    route: {},
    class: { 'is-current': route.meta.firstRoute === '標籤管理' },
    children: [
      {
        name: '自訂標籤',
        route: { name: 'tags-custom' },
        class: {
          'is-active': route.name === 'tags-custom'
            || route.name === 'tags-add'
            || route.name === 'tags-edit'
            || (route.name === 'tags-view' && route.query.source === 'tags-custom')
            || route.name === 'tags-category'
            || route.name === 'tags-category-edit'
            || route.name === 'tags-upload',
        },
      },
      {
        name: '外部匯入',
        route: { name: 'tags-external' },
        class: { 'is-active': route.name === 'tags-external' || route.query.source === 'tags-external' },
      },
      {
        name: '智慧標籤',
        route: { name: 'tags-system' },
        class: { 'is-active': route.name === 'tags-system' || route.query.source === 'tags-system' },
        tag: 'BETA',
      },
    ],
  },
  {
    name: '分群受眾',
    authKey: 'm_f',
    icon: 'icon-audience',
    route: {},
    class: { 'is-current': route.meta.firstRoute === '分群受眾' },
    children: [
      {
        name: '自訂受眾',
        route: { name: 'targetCustomList' },
        class: {
          'is-active': route.name === 'targetCustomList'
            || route.name === 'targetCustomAdd'
            || route.name === 'targetCustomEdit'
            || route.name === 'targetCustomView'
            || (route.name === 'targetDashboard' && route.params.type === 'custom'),
        },
      },
      {
        name: '智慧名單',
        route: { name: 'smartList' },
        class: { 'is-active': route.name === 'smartList' || (route.name === 'targetDashboard' && route.params.type === 'smart') },
      },
    ],
  },
  {
    name: '行銷管理',
    authKey: 'm_g',
    icon: 'icon-marketing',
    route: {},
    class: { 'is-current': route.meta.firstRoute === '行銷管理' },
    children: [
      {
        name: '行銷自動化',
        route: { name: 'activity-custom' },
        class: { 'is-active': stringIndexOfString(route.name, ['activity', 'script']) },
      },
      {
        name: '模板管理',
        route: { name: 'template' },
        class: {
          'is-active': route.name === 'template'
            || route.name === 'template-add'
            || route.name === 'template-edit'
            || route.name === 'template-view',
        },
      },
      {
        name: '成效分析',
        route: { name: 'analytics' },
        class: { 'is-active': route.name === 'analytics' },
      },
    ],
  },
  {
    name: '設定',
    authKey: 'm_h',
    icon: 'vue-feather-settings',
    route: {},
    class: { 'is-current': route.meta.firstRoute === '設定' },
    children: [
      {
        name: '帳號管理',
        route: { name: 'setting-account' },
        class: {
          'is-active': route.name === 'setting-account'
            || route.name === 'setting-account-add'
            || route.name === 'setting-account-edit',
        },
      },
      {
        name: '行銷渠道管理',
        route: { name: 'setting-channel', params: { channel: 'sms' } },
        class: { 'is-active': route.name === 'setting-channel' },
      },
      {
        name: '發送設定',
        route: { name: 'setting-send' },
        class: { 'is-active': route.name === 'setting-send' },
      },
    ],
  },
]));

function getChildKey(parent, index) {
  return parent.authKey.split('_')[1].concat(`_${String(index + 1).padStart(2, '0')}`);
}

function ifChildrenNotAllHidden(obj) {
  return !Object.keys(obj.menus).every(key => !obj.menus[key].display);
}

function getCorpDynamicField(key, childKey: any = null) {
  if (childKey)
    return corpDynamicField.value !== null ? corpDynamicField.value.menus[key].menus[childKey] : null;

  return corpDynamicField.value !== null ? corpDynamicField.value.menus[key] : null;
}

function manualListCorpDynamicField(key): any {
  if (corpDynamicField.value !== null && key) {
    if (key.includes('m_')) {
      const menus = corpDynamicField.value.menus[key].menus;
      const menuKeys = Object.keys(menus);

      if (menuKeys.length > 0) {
        return {
          display: menuKeys.some(a => menus[a].display),
          enable: menuKeys.some(a => menus[a].enable),
        };
      }
      return {
        display: corpDynamicField.value.menus[key].display,
        enable: corpDynamicField.value.menus[key].enable,
      };
    }
    else {
      const menuParentKey = `m_${key.split('_')[0]}`;
      return corpDynamicField.value.menus[menuParentKey].menus[key];
    }
  }
  return null;
}

async function hashRoute(hash) {
  await router.push({ name: 'teachingCenter' });
  await router.push({ name: 'teachingCenter', query: { hash } });
}

function handleSelect(key: string, keyPath: string[], item: MenuItemClicked) {
  sidemenuStore.routerChange(item);
}
watch(
  () => route.name,
  () => {
    setTimeout(() => {
      if (window.innerWidth >= 1900)
        sidemenuStore.openMenu();
      else
        sidemenuStore.collapseMenu();
    }, 500);
  },
);

function handleMenu(payload: boolean) {
  if (window.innerWidth >= 1900)
    sidemenuStore.openMenu();

  else
    payload ? sidemenuStore.openMenu() : sidemenuStore.collapseMenu();
}

const defaultActive = computed(() => route.path);

onMounted(() => {
  if (window.innerWidth >= 1900)
    sidemenuStore.openMenu();
  window.addEventListener('resize', () => {
    if (window.innerWidth >= 1900)
      sidemenuStore.openMenu();
  });
});

function stringIndexOfString(str, arr) {
  let pass = false;
  arr.forEach((a) => {
    if (str.includes(a))
      pass = true;
  });
  return pass;
}

const exteranlUrlShow = computed(() => {
  const origin = window.location.origin;
  return [
    'http://localhost',
    'http://10.146.0.18:8087',
    'http://10.146.0.18:8108',
    'http://10.146.0.18:8082',
    'https://sit.cdpapp.eagleeye.com.tw',
    'https://uat.cdpapp.eagleeye.com.tw',
    'https://demo.cdpapp.eagleeye.com.tw',
  ].some(a => origin.includes(a));
});

const externalUrl = ref([
  { name: '廣告數據成效', href: 'https://lookerstudio.google.com/reporting/72c3959a-13c8-4b8f-b0f6-7baf2183805f/page/p_3ddwc8f6xc' },
  { name: '網站管理員', href: 'https://lookerstudio.google.com/u/0/reporting/286e6f6d-73ca-46d5-8985-805d19c619ef/page/p_f7zz54nhmc' },
  { name: '品牌網紅分析', href: 'https://lookerstudio.google.com/u/0/reporting/ffb5eb57-097c-4e4f-b9e6-96124e19955b/page/p_s78rrhx02c' },
  { name: '市場消費洞察', href: 'https://lookerstudio.google.com/u/0/reporting/320f8c5e-0acd-41dd-8577-eb703f5580da/page/p_om8wfsiw2c' },
]);

function handleLink(url) {
  window.open(url);
}
</script>

<template>
  <div class="mainframe-sidemenu" data-cy="mainframe-sidemenu" @mouseover="handleMenu(true)" @mouseleave="handleMenu(false)">
    <!-- @open="handleOpen"
    @close="handleClose" -->
    <el-scrollbar height="100%">
      <el-menu
        class="el-menu-main el-menu-vertical"
        :collapse="globalSidemenu.isCollapse"
        router
        :default-active="defaultActive"
        @select="handleSelect"
      >
        <template v-for="item in menuList">
          <template v-if="item.children.length === 0 && item.route !== 'externalUrl'">
            <el-menu-item
              v-if="getCorpDynamicField(item.authKey) ? getCorpDynamicField(item.authKey).display : true"
              :key="item.name"
              :disabled="getCorpDynamicField(item.authKey) ? !getCorpDynamicField(item.authKey).enable : false"
              :index="item.name"
              :route="item.route"
              :class="item.class"
              :data-cy="item.name"
            >
              <el-icon>
                <vue-feather v-if="item.icon.includes('vue-feather-')" :type="item.icon.substring(12)" size="20" />
                <i v-else :class="item.icon" />
              </el-icon>
              <template #title>
                <div class="lh-1">
                  {{ item.name }}
                </div>
              </template>
            </el-menu-item>
          </template>
          <template v-else>
            <template v-if="item.route !== 'externalUrl' || (item.route === 'externalUrl' && exteranlUrlShow)">
              <el-sub-menu
                v-if="getCorpDynamicField(item.authKey) ? ifChildrenNotAllHidden(getCorpDynamicField(item.authKey)) : true"
                :key="item.name"
                popper-class="el-sub-popmenu"
                :index="item.name"
                :class="item.class"
                :data-cy="item.name"
                @click="sidemenuStore.openMenu"
              >
                <template #title>
                  <el-icon>
                    <vue-feather v-if="item.icon.includes('vue-feather-')" :type="item.icon.substring(12)" size="20" />
                    <i v-else :class="item.icon" />
                  </el-icon>
                  <span class="lh-1">{{ item.name }}</span>
                </template>
                <template v-if="item.route === 'externalUrl'">
                  <template v-for="(urlItem, urlItemInex) in externalUrl">
                    <div
                      v-if="getCorpDynamicField(item.authKey, getChildKey(item, urlItemInex)) ? getCorpDynamicField(item.authKey, getChildKey(item, urlItemInex)).display : true"
                      :key="urlItem.name"
                      @click="!(getCorpDynamicField(item.authKey, getChildKey(item, urlItemInex)) ? !getCorpDynamicField(item.authKey, getChildKey(item, urlItemInex)).enable : false) ? handleLink(urlItem.href) : null"
                    >
                      <el-menu-item
                        :route="null"
                        :index="urlItem.name"
                        :disabled="true"
                        class="external-url-menu-item"
                        :class="{ 'external-url-menu-item--disabled': getCorpDynamicField(item.authKey, getChildKey(item, urlItemInex)) ? !getCorpDynamicField(item.authKey, getChildKey(item, urlItemInex)).enable : false }"
                      >
                        <div class="lh-1">
                          {{ urlItem.name }}
                        </div>
                      </el-menu-item>
                    </div>
                  </template>
                </template>
                <template v-else>
                  <template v-for="(child, childIndex) in item.children">
                    <el-menu-item
                      v-if="getCorpDynamicField(item.authKey, getChildKey(item, childIndex)) ? getCorpDynamicField(item.authKey, getChildKey(item, childIndex)).display : true"
                      :key="child.name"
                      :disabled="getCorpDynamicField(item.authKey, getChildKey(item, childIndex)) ? !getCorpDynamicField(item.authKey, getChildKey(item, childIndex)).enable : false"
                      :index="child.name"
                      :route="child.route"
                      :class="child.class"
                      :data-cy="`${item.name} - ${child.name}`"
                    >
                      <div class="flex items-center lh-1">
                        {{ child.name }}
                        <span v-if="child.tag" class="flex items-center text-12px font-bold c-#bfc1c7 px-4px h-18px lh-12px b-1px b-solid b-#ddd rd-3px ml-8px">{{ child.tag }}</span>
                      </div>
                    </el-menu-item>
                  </template>
                </template>
              </el-sub-menu>
            </template>
          </template>
        </template>
        <el-sub-menu
          popper-class="el-sub-popmenu"
          index="教學中心"
          :class="{ 'is-current': route.meta.firstRoute === '教學中心' }"
          data-cy="教學中心"
          @click="sidemenuStore.openMenu"
        >
          <template #title>
            <el-icon>
              <vue-feather type="book-open" size="20" />
            </el-icon>
            <span class="lh-1">
              教學中心
            </span>
          </template>
          <template v-for="teachItem in manualList.data || []" :key="teachItem">
            <el-menu-item
              v-if="manualListCorpDynamicField(teachItem.authKey) ? manualListCorpDynamicField(teachItem.authKey).display : true"
              :disabled="manualListCorpDynamicField(teachItem.authKey) ? !manualListCorpDynamicField(teachItem.authKey).enable : false"
              :index="'教學中心/'.concat(teachItem.name)"
              :class="{ 'is-active': route.query.hash === teachItem.name }"
              :data-cy="`教學中心 - ${teachItem.name}`"
              @click="hashRoute(teachItem.name)"
            >
              <div class="lh-1">
                {{ teachItem.name }}
              </div>
            </el-menu-item>
          </template>
        </el-sub-menu>
      </el-menu>
    </el-scrollbar>
  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/scss/abstracts/variables";

$menu-active: #98a0b3;

.mainframe-sidemenu {
  position: fixed;
  top: 61px;
  left: 0;
  z-index: 10;
  height: calc(100% - 61px);
  min-height: calc(100% - 61px);
  background-color: $white;
  box-shadow: 0 5px 5px #00000029;

  :deep(.el-scrollbar) {
    .el-scrollbar__view {
      padding: 20px 10px;
    }
  }

  :deep(.el-menu-main) {
    border-right: 0;
    --el-menu-base-level-padding: 13px;
    --el-menu-level-padding: 13px;
    --el-menu-icon-width: 20px;

    &:not(.el-menu--collapse) {
      width: 208px;

      > .el-menu-item {
        &:hover {
          background-color: $bg-light;
        }

        &.is-active {
          color: $white;
          background-color: $menu-active;

          .el-icon {
            color: $white;
          }
        }

        &:not(.is-active).is-current {
          color: $white;
          background-color: $menu-active;

          .el-icon {
            color: $white;
          }
        }
      }

      .el-icon {
        color: #8d8f95;
      }
    }

    &.el-menu--collapse {
      .el-menu-item,
      .el-sub-menu__title {
        color: #8d8f95;
      }

      > .el-menu-item {
        background-color: transparent;

        &:hover {
          background-color: $bg-light;
        }

        &.is-active {
          color: $white;
          background-color: $menu-active;

          .el-icon {
            color: $white;
          }
        }

        &:not(.is-active).is-current {
          color: $white;
          background-color: $menu-active;

          .el-icon {
            color: $white;
          }
        }
      }

      > .el-menu-item [class^="el-icon"] {
        margin-right: 0;
      }

      .el-sub-menu {
        &.is-active {
          color: $white;
          background-color: $menu-active;

          .el-icon {
            color: $white;
          }

          .el-sub-menu__title,
          .el-sub-menu__title i {
            color: $white;
          }
        }

        &:not(.is-active).is-current {
          color: $white;
          background-color: $menu-active;

          .el-icon {
            color: $white;
          }
        }
      }
    }

    > li {
      margin: 0 auto 3px;
    }

    .el-menu-item,
    .el-sub-menu__title {
      height: 46px;
      color: #1e1e1e;
      border-radius: 15px;
    }

    .el-menu-item [class^="el-icon"],
    .el-sub-menu .el-icon {
      margin-right: 16px;
    }

    .el-icon svg {
      width: 20px;
      height: 20px;
    }

    i.el-icon [class^="icon-"] {
      font-size: 20px;
    }

    .el-sub-menu {
      border-radius: 15px;

      &.is-opened {
        .el-sub-menu__title {
          background-color: $bg-light;

          &:hover {
            background-color: $bg-light;
          }
        }
      }

      &.is-active {
        .el-sub-menu__title {
          color: $white;
          background-color: $menu-active;

          .el-icon {
            color: $white;
          }

          &:hover {
            color: $white;
            background-color: $menu-active;

            .el-icon {
              color: $white;
            }
          }
        }

        .el-sub-menu__icon-arrow {
          color: $white;
        }
      }

      &:not(.is-active).is-current {
        .el-sub-menu__title {
          color: $white;
          background-color: $menu-active;

          .el-icon {
            color: $white;
          }

          &:hover {
            color: $white;
            background-color: $menu-active;

            .el-icon {
              color: $white;
            }
          }
        }
      }

      .el-menu {
        position: relative;

        &::before {
          position: absolute;
          top: 50%;
          left: 22px;
          width: 2px;
          height: calc(100% - 20px);
          background-color: #ddd;
          transform: translateY(-50%);
          content: "";
        }
      }

      .el-sub-menu__title {
        &:hover {
          background-color: $bg-light;
        }
      }

      .el-menu-item {
        height: 40px;
        padding-left: 50px;
        color: #8d8f95;

        &:hover {
          color: $primary;
          background-color: unset;
        }

        &.is-active {
          color: $primary;
          font-weight: bold;
        }

        &.is-disabled {
          color: #ccc;
          cursor: not-allowed;
          user-select: none;

          &:hover {
            color: #ccc;
            cursor: not-allowed;
          }
        }

        &.external-url-menu-item {
          &.is-disabled {
            color: #8d8f95;
            cursor: pointer;
            opacity: 1;
            user-select: auto;

            &:hover {
              color: #462887;
              cursor: pointer;
            }
          }
          &.external-url-menu-item--disabled {
            color: #ccc;
            cursor: not-allowed;
            opacity: 0.25;
            user-select: none;

            &:hover {
              color: #ccc;
              cursor: not-allowed;
            }
          }
        }
      }

      .el-sub-menu__icon-arrow {
        color: #bec0c7;
      }
    }
  }

  :deep(.el-scrollbar__bar) {
    &.is-vertical {
      right: 0;
    }
  }
}
</style>

<style lang="scss">
@import "@/assets/scss/abstracts/variables";

.el-sub-popmenu {
  .el-menu-item {
    &:hover {
      color: $primary;
      background-color: transparent;
    }

    &.is-active {
      color: $primary;
      font-weight: bold;
    }
  }
}
</style>
