<script lang="ts" setup>
import { useRouter } from 'vue-router';
import dayjs from 'dayjs';
import useLoginStore from '@/stores/login';

const router = useRouter();
const loginStore = useLoginStore();
const loginInfo = computed(() => loginStore.loginInfo);

const etlDate = computed(() => {
  const date = loginStore.etlLastCompleteVersion;
  return date ? dayjs(String(date).substring(0, 8)).add(-1, 'days').format('YYYY-MM-DD') : '-';
});

const ifEtlCompleted = computed(() => {
  const yesterday = dayjs(dayjs().add(-1, 'day').format('YYYY-MM-DD')).valueOf();
  return dayjs(etlDate.value).valueOf() ? dayjs(etlDate.value).valueOf() >= yesterday : false;
});

function logout() {
  loginStore.setLogout();
  router.push({ name: 'login' });
}

function handlePop(payload: boolean) {
  loginStore.setLoginPopover(payload);
}
</script>

<template>
  <div class="mainframe-header d-flex align-items-center justify-content-between">
    <div class="header-logo d-flex align-items-center">
      <img src="@/assets/images/header_logo.svg" alt="Eagleeye CDP">
    </div>
    <div class="mainframe-header__right">
      <div class="mainframe-header__etl lh-20px">
        <TooltipLabel
          v-if="!ifEtlCompleted"
          type="danger"
          content="CDP每日執行ETL產生錯誤，無法正常更新至昨日資料，請聯絡系統管理員做處理！"
        />
        資料同步至：
        <span class="mainframe-header__etl-value" data-cy="etl-date">{{ etlDate }}</span>
      </div>
      <el-popover
        :visible="loginStore.loginPopover"
        placement="top"
        :width="250"
      >
        <div
          @mouseover="handlePop(true)"
          @mouseleave="handlePop(false)"
        >
          <div class="login-info">
            <span>{{ loginInfo.name }}</span>
            <span class="login-contact-method">{{ loginInfo.email }}</span>
            <el-divider />
            <div>
              <span class="login-role">({{ loginInfo.role }})</span>
            </div>
          </div>
          <el-button size="small" @click="logout">
            登出
          </el-button>
        </div>

        <template #reference>
          <div
            class="header-avater d-flex align-items-center justify-content-center"
            @click="handlePop(true)"
          >
            <span>{{ loginInfo.name.slice(0, 1).toLocaleUpperCase() || '' }}</span>
          </div>
        </template>
      </el-popover>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/scss/abstracts/variables";

.mainframe-header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  min-height: 60px;
  max-height: 60px;
  padding: 10px 20px;
  background-color: $white;
  box-shadow: 0 1px 0 $border;

  &__right {
    display: flex;
    align-items: center;
  }

  &__etl {
    display: inline-flex;
    padding-right: 14px;
    color: #1b1d21;
    font-size: 14px;

    .tooltip-info {
      padding-right: 5px;
    }

    &-value {
      color: $primary;
    }
  }
}

.header-logo {
  max-width: 170px;
}

.header-avater {
  width: 36px;
  height: 36px;
  color: $white;
  background-color: #c5c2e0;
  border-radius: 50%;
  cursor: pointer;
}

.login-info {
  display: flex;
  flex-direction: column;
  color: #616161;

  .login-contact-method,
  .login-role {
    color: #8d8f95;
  }

  .el-divider {
    margin: 7.5px 0 4.5px;
  }
}

.el-button {
  width: 90px;
  margin-top: 7px;
}
</style>
